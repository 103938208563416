import lozad from 'lozad/dist/lozad'

window.tomesto = window.tomesto || {};
tomesto.isBot = !('onscroll' in window) || /(gle|ing)bot|yandex\.com\/bots/.test(navigator.userAgent);
tomesto.lazyload_reveal = function lazyload_reveal(selector) {
  var selector = (typeof selector !== 'undefined') ?  selector : '.lozad[data-srcset], .splide_lazy[data-splide-lazy-srcset]';
  var els = document.querySelectorAll(selector);
  for(var i = 0; i < els.length; i++) {
    els[i].setAttribute('srcset', els[i].dataset.srcset || els[i].dataset.splideLazySrcset);
  }
};

if (tomesto.isBot) {
  domready(function() { tomesto.lazyload_reveal() });
} else if (!!window.IntersectionObserver) {
  tomesto.lozad = tomesto.lozad || lozad('.lozad', { rootMargin: '500px 800px' });
  domready(tomesto.lozad.observe);
}
